// @flow
import GA from "react-ga4";
import Configuration from "../Configuration.js";
import {SHOW_DOCUMENTS} from "../actions/document";
import {DMS_CART_IMPORT_REQUEST, EPC_CART_IMPORT_REQUEST, ITEM_ADD_REQUEST, ITEM_REMOVE_REQUEST} from "../cart/actions";
import {DELETE_DISPOSAL_REQUEST, PLACE_DISPOSAL_REQUEST, PLACE_DISPOSALS_REQUEST} from "../order/actions/orders";
import {SET_VEHICLE, VK_FACET_SET} from "../vehicle/action";
import {FETCH_ALL_NEWS_SUCCESS} from "../news/actions";
import {CALCULATE_OFFERS_SUCCESS, CREATE_ORDERS_SUCCESS} from "../order/actions/offer";
import {FETCH_CHANNELS, QUESTIONNAIRE_FORM_SUBMIT} from "../medialib/actions/medialib";
import {COURSE_PAGE_VISITED} from "../medialib/container/SeriesPageContainer";
import {CHANNEL_PAGE_VISITED} from "../medialib/container/ChannelPageContainer";
import {
	SEND_MISSING_PARTCODE_NOTIFICATION_SUCCESS,
	SET_PRODUCT_FILTER_PARAMETERS,
	SET_PRODUCTS_SEARCH_PARTCODE
} from "../productsearch/actions/search";
import {
	WATCH_LIST_CREATE_SUCCESS,
	WATCH_LIST_DELETE_SUCCESS,
	WATCH_LIST_ITEM_ADD_SUCCESS,
	WATCH_LIST_ITEM_REMOVE_SUCCESS,
	WATCH_LIST_ITEM_TRANSFER_SUCCESS
} from "../watchlist/actions";
import {
	ACCEPT_PROPOSAL,
	CREATE_PROPOSAL,
	FINISH_PROPOSAL,
	OPEN_PROPOSALS,
	PRINT_PROPOSAL,
	REJECT_PROPOSAL
} from "../proposal/action";
import {SET_LANGUAGE} from "../i18n/i18n";
import {PART_RESET} from "../documentsearch/actions/searchPart";
import {LOGIN_SUCCESS, LOGOUT} from "../service/authService";
import MandatorProvider, {MANDATOR} from "../mandator/MandatorProvider";
import {VEHICLE} from "../dms/action";
import EcommerceEventEmitter from "./EcommerceEventEmitter";
import {
	FETCH_ADVENT_CALENDAR,
	OPEN_ADVENT_CALENDAR_DOOR_MODAL,
	SEND_ADVENT_CALENDAR_DOOR_ACCESSORY_NAVIGATOR_ORDER,
	SEND_ADVENT_CALENDAR_DOOR_CODE,
	SEND_ADVENT_CALENDAR_DOOR_ORDER
} from "../advent/actions";

export const EXTERNAL_TIRESHOP_VISITED = "EXTERNAL_TIRESHOP_VISITED";
export const EXTERNAL_BATTERYSHOP_VISITED = "EXTERNAL_BATTERYSHOP_VISITED";
export const EXTERNAL_ACCESSORY_NAVIGATOR_VISITED = "EXTERNAL_ACCESSORY_NAVIGATOR_VISITED";
export const CROSS_SELLING = "CROSS_SELLING";
export const SUPPORT_MAIL = "SUPPORT_MAIL";
export const GUIDE_VISITED = "GUIDE_VISITED";
export const FAQ_VISITED = "FAQ_VISITED";
export const GTA_LOADED = "GTA_LOADED";

export const DOWNLOAD_INSPECTION_DOCUMENT = "DOWNLOAD_INSPECTION_DOCUMENT";

export const initializeTracking = (dealerId: string) => {
	GA.reset();
	const TRACKING_ID = Configuration.value("trackingId");
	if (navigator.doNotTrack === "1") {
		window["ga-disable-" + TRACKING_ID] = true;
	}
	const gtagOptions = {
		anonymizeIp: true,
		dimension1: dealerId
	};

	GA.initialize([{
		trackingId: TRACKING_ID,
		gaOptions: {debug: false},
		gtagOptions
	}]);
};

let track = function (action) {
	switch (action.type) {
		case PART_RESET:
			GA.event({category: "filter", action: "reset", label: "delete all filters"});
			break;

		case FETCH_CHANNELS.SUCCESS: {
			GA.event({
				category: "MediaLib",
				action: "openedMediaLib"
			});
			break;
		}
		case COURSE_PAGE_VISITED: {
			GA.event({
				category: "MediaLib",
				action: "openedVideo:",
				label: `Video: ${action.payload}`
			});
			break;
		}
		case SET_LANGUAGE: {
			GA.event({
				category: "Configuration",
				action: "setLanguage",
				label: `Language: ${action.payload}`
			});
			break;
		}
		case CHANNEL_PAGE_VISITED: {
			GA.event({
				category: "MediaLib",
				action: "openedChannel:",
				label: `Channel: ${action.payload}`
			});
			break;
		}
		case QUESTIONNAIRE_FORM_SUBMIT.SUCCESS: {
			GA.event({
				category: "MediaLib",
				action: "sendSurvey",
				label: `Video: ${action.payload}`
			});
			break;
		}
		case VK_FACET_SET: {
			GA.event({
				category: "VehicleFilter",
				action: action.key + "Set"
			});
			break;
		}
		case EXTERNAL_TIRESHOP_VISITED: {
			GA.event({
				category: "External",
				action: "Tireshop",
			});
			break;
		}
		case EXTERNAL_BATTERYSHOP_VISITED: {
			GA.event({
				category: "External",
				action: "Batteryshop",
			});
			break;
		}
		case ITEM_ADD_REQUEST: {
			GA.event({
				category: "Cart",
				action: "AddItem",
			});
			break;
		}
		case ITEM_REMOVE_REQUEST: {
			GA.event({
				category: "Cart",
				action: "RemoveItem",
			});
			break;
		}
		case GTA_LOADED: {
			GA.event({
				category: "GTA",
				action: "VisitedGtaPage",
			});
			break;
		}
		case SET_PRODUCTS_SEARCH_PARTCODE: {
			GA.event({
				category: "GTA",
				action: "PartCodeSearched",
				label: `PartCode: ${action.partCode}`
			});
			break;
		}
		case SEND_MISSING_PARTCODE_NOTIFICATION_SUCCESS: {
			GA.event({
				category: "GTA",
				action: "SendMissingPartCodeNotification",
				label: `PartCode:${action.payload.partCode} Vin: ${action.payload.vin}`
			});
			break;
		}
		case SET_PRODUCT_FILTER_PARAMETERS: {
			action.fieldValue.map(fieldValue => GA.event({
				category: "GTA",
				action: "PartCodeGTA",
				label: `PartCode: ${fieldValue.partNo}`
			}));
			break;
		}
		case EPC_CART_IMPORT_REQUEST: {
			GA.event({
				category: "Cart",
				action: "ImportEpcCart",
			});
			break;
		}
		case DMS_CART_IMPORT_REQUEST: {
			GA.event({
				category: "Cart",
				action: "ImportDmsCart",
			});
			break;
		}
		case GUIDE_VISITED: {
			GA.event({
				category: "Menu",
				action: "GuideVisited",
			});
			break;
		}
		case FAQ_VISITED: {
			GA.event({
				category: "Menu",
				action: "FAQVisited",
			});
			break;
		}
		case SUPPORT_MAIL: {
			GA.event({
				category: "Menu",
				action: "SupportMailOpened",
			});
			break;
		}
		case SET_VEHICLE: {
			GA.event({
				category: "VehicleFilter",
				action: "usedFIN",
			});
			break;
		}
		case SHOW_DOCUMENTS: {
			GA.event({
					category: "ProductList",
					action: "Documents",
					label: action.partNumber
				}
			);
			break;
		}
		case PLACE_DISPOSAL_REQUEST: {
			GA.event({
				category: "Order",
				action: "Place",
				label: `OrderId: ${action.disposal.orderId}`,
				value: 1
			});
			EcommerceEventEmitter.createPurchaseEvent(GA, action.disposal);
			break;
		}
		case DELETE_DISPOSAL_REQUEST: {
			GA.event({
				category: "Order",
				action: "Delete",
				label: `OrderId: ${action.disposalId}`,
				value: 1
			});
			break;
		}
		case PLACE_DISPOSALS_REQUEST: {
			GA.event({
				category: "Order",
				action: "BulkPlace",
				label: `OrderIds: ${action.disposals.map(disposal => disposal.orderId).join(", ")}`,
				value: action.disposals.length
			});
			action.disposals.forEach(disposal => EcommerceEventEmitter.createPurchaseEvent(GA, disposal));
			break;
		}
		case FETCH_ALL_NEWS_SUCCESS: {
			GA.event({
				category: "News",
				action: "NewsVisited",
				label: action.payload.id.toString()
			});
			break;
		}
		case DOWNLOAD_INSPECTION_DOCUMENT: {
			GA.event({
				category: "InspectionLists",
				action: "InspectionListVisited",
				label: action.payload.toString()
			});
			break;
		}
		case CALCULATE_OFFERS_SUCCESS: {
			GA.event({
				category: "Offers",
				action: "OfferCalculationComplete",
				value: action.offerBundle.offers.length
			});
			break;
		}
		case CREATE_ORDERS_SUCCESS: {
			GA.event({
				category: "Order",
				action: "OrdersCreated",
				label: `OrderIds: ${action.orderBundle.map(orderBundle => orderBundle.orderId).join(", ")}`,
				value: action.orderBundle.length
			});
			break;
		}
		case WATCH_LIST_ITEM_ADD_SUCCESS: {
			GA.event({
				category: "Watchlist",
				action: "AddItemToWatchlist",
			});
			break;
		}
		case WATCH_LIST_CREATE_SUCCESS: {
			GA.event({
				category: "Watchlist",
				action: "AddWatchlist",
			});
			break;
		}
		case WATCH_LIST_DELETE_SUCCESS: {
			GA.event({
				category: "Watchlist",
				action: "DeleteWatchlist",
			});
			break;
		}
		case WATCH_LIST_ITEM_REMOVE_SUCCESS: {
			GA.event({
				category: "Watchlist",
				action: "DeleteWatchlistItem",
			});
			break;
		}
		case WATCH_LIST_ITEM_TRANSFER_SUCCESS: {
			GA.event({
				category: "Cart",
				action: "AddWatchlistItem",
				value: action.amount
			});
			break;
		}
		case OPEN_PROPOSALS: {
			GA.event({
				category: "Proposals",
				action: "OpenProposals",
			});
			break;
		}
		case CREATE_PROPOSAL.SUCCESS: {
			GA.event({
				category: "Proposals",
				action: "CreateProposal",
			});
			break;
		}
		case FINISH_PROPOSAL.SUCCESS: {
			GA.event({
				category: "Proposals",
				action: "CloseProposal",
			});
			break;
		}
		case ACCEPT_PROPOSAL.SUCCESS: {
			GA.event({
				category: "Proposals",
				action: "AcceptProposal",
			});
			break;
		}
		case REJECT_PROPOSAL.SUCCESS: {
			GA.event({
				category: "Proposals",
				action: "RejectProposal",
			});
			break;
		}
		case PRINT_PROPOSAL.SUCCESS: {
			GA.event({
				category: "Proposals",
				action: "PrintProposal",
			});
			break;
		}
		case VEHICLE.FOUND: {
			GA.event({
				category: "DMS",
				action: "GetCustomerData",
			});
			break;
		}
		case FETCH_ADVENT_CALENDAR.SUCCESS: {
			GA.event({
				category: "Advent",
				action: "OpenAdventCalendar",
			});
			break;
		}
		case SEND_ADVENT_CALENDAR_DOOR_CODE.SUCCESS: {
			if (action.payload.unlocked) {
				GA.event({
					category: "Advent",
					action: "EnterAdventCalendarCode",
					label: action.payload.day
				});
			}
			break;
		}
		case SEND_ADVENT_CALENDAR_DOOR_ORDER.SUCCESS: {
			GA.event({
				category: "Advent",
				action: "OrderAdventCalendarTreat",
				value: action.quantity,
				label: action.partNumber
			});
			break;
		}
		case SEND_ADVENT_CALENDAR_DOOR_ACCESSORY_NAVIGATOR_ORDER.SUCCESS: {
			GA.event({
				category: "Advent",
				action: "OrderAdventCalendarAccessoryNavigatorTreat",
				label: action.payload.day
			});
			break;
		}
		case OPEN_ADVENT_CALENDAR_DOOR_MODAL: {
			GA.event({
				category: "Advent",
				action: "OpenAdventCalendarDoorModal",
				label: action.payload
			});
			break;
		}
		default: {
			break;
		}
	}
};

export const viewSusy = () => {
	GA.event({
		category: "Susy",
		action: "OpenSusy"
	});
};

export const viewSusyTicket = (ticketId: number) => {
	GA.event({
		category: "Susy",
		action: "ViewSusyTicket",
		label: `SusyTicketId: ${ticketId}`
	});
};

export const viewSusyArticle = (articleId: number) => {
	GA.event({
		category: "Susy",
		action: "ViewSusyArticle",
		label: `SusyArticleId: ${articleId}`
	});
};

export const tracker = store => next => action => {
	if (action.type === LOGIN_SUCCESS) {
		const isTrackingEnabled = action.mandator === MANDATOR.SUBARU.DE;
		const dealerId = action.dealerId;
		if (isTrackingEnabled) initializeTracking(dealerId);
	}
	if (action.type === LOGOUT) {
		GA.reset();
	}
	try {
		if (MandatorProvider.isTrackingEnabled()) track(action);
	} catch (e) {
		console.error("Can't track action", e);
	}
	return next(action);
};
